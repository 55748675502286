<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label for="date_from">Date From</label>
            <b-input
              id="date_from"
              v-model="filter.date_from"
              type="date"
              @change="onFilterChanged"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label for="date_from">Date To</label>
            <b-input
              id="date_from"
              v-model="filter.date_to"
              type="date"
              @change="onFilterChanged"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <div class="form-group">
            <label>Filter By Team</label>
            <b-select
              v-model="filter.team"
              :options="options.teams"
              @change="onFilterChanged"
            />
          </div>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Search String</label>
            <b-input
              v-model="tableSettings.filter"
              type="text"
              placeholder="Enter search string"
              debounce="1000"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-table
        ref="table"
        hover
        responsive
        class="mt-2"
        :items="myProvider"
        :fields="tableSettings.fields"
        :filter="tableSettings.filter"
        :filter-included-fields="tableSettings.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>

        <template #cell(raw_data_enrolled)="data">
          <b-container>
            <b-row class="text-nowrap">
              <b-col
                sm="2"
                align-self="center"
              >
                <h6><b> {{ parseInt(data.item.raw_data_enrolled) }} </b></h6>
              </b-col>
              <b-col sm="10">
                <span class="fontsize-sm m-0 text-success">
                  <b>{{ `${data.item.with_mobile_no}` }}</b>
                </span><br>
                <span class="fontsize-sm m-0 text-warning">
                  <b>{{ `${data.item.without_mobile_no}` }}</b>
                </span>
              </b-col>
            </b-row>
          </b-container>
        </template>

        <template #cell(lga)="data">
          <div
            class="text-nowrap"
            align-self="center"
          >
            <h6><b>{{ `${data.item.lga}` }}</b></h6>
          </div>
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>
      </b-table>
    </b-card>
  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import { SupervisorMonitoringService } from '@/services'
import formatter from '@/mixins/formatter'

export default {
  name: 'SupervisorLGARawData',

  middleware: ['auth', 'supervisor'],

  metaInfo () {
    return {
      title: 'Lead Agent Raw Data'
    }
  },

  mixins: [formatter],

  data () {
    return {
      state: {
        busy: false
      },
      filter: {
        team: 'All',
        date_from: this.dateFormatter(Date.now()),
        date_to: this.dateFormatter(Date.now())
      },
      options: {
        teams: [
          { text: 'All', value: 'All' },
          { text: 'Luzon', value: 'Luzon' },
          { text: 'VisMin', value: 'VisMin' }
        ]
      },
      tableSettings: {
        filter: '',
        filterOn: [],
        fields: [
          'index',
          { key: 'lga', label: 'LGA' },
          { key: 'team' },
          { key: 'raw_data_enrolled', label: 'Raw Data' },
          { key: 'untouched', label: 'Untouched' },
          { key: 'processed', label: 'Processed' },
          { key: 'unsuccessful_calls', label: 'Unsuccessful Calls' },
          { key: 'invalid_raw_data', label: 'Invalid' }
        ]
      }
    }
  },

  mounted () {
    core.index()
  },

  methods: {
    async myProvider (ctx) {
      try {
        const { data } = await SupervisorMonitoringService.getLGARawDAta(
          this.objectToUrl({
            filter_text: ctx.filter,
            filter_team: this.filter.team,
            filter_from: this.filter.date_from,
            filter_to: this.filter.date_to
          })
        )
        return data.items
      } catch {
        return []
      }
    },

    onFilterChanged () {
      this.$refs.table.refresh()
    }
  }
}
</script>
